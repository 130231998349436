/**
 * Emergency Message Ciper Page
 * 
 * Show the two letter cipher that helps users fill in the blanks on the main
 * Emergency Message Page.
 * 
 * NOTE: This is currently unused and unfinished. Eventually the codes should not be hardcoded,
 * and should be editable within the hunt admin, to allow the watson team to update what the
 * codes are. The format of the codes should match the hardcoded version, where the codes and
 * words are separated by an equal sign, and each pair is separated by a comma.
 */

import React, { useContext, useState, useEffect } from 'react'
import AppContext from '../../utils/context'

import LoggedInWrapper from '../../components/loggedinWrapper'
import SvgArrow from '../../components/svg/arrow'
import Link from '../../components/link'
import { getCurrentHuntData } from '../../utils/hunt-data'

const codes = `BL=Russians,CL=files,DD = disable, DE=crash,EG=4delos,ES=block,FU=investigate,FV=Al Qaeda,GT=Facebook,HR=virus, HS=gmail.com, IF = de-activation,IO=information, IP=CIA,KA=Pentagon,KP=activation,LB=Silicon Valley, LE=hidden, LH=contact, ML=infiltrate, MO=killed, NU=encrypted, OM= U.S., OR=North Koreans, OS = receive, PC= spy ring, PL=text, PU=ip3mQZ,RA=weapons, RE=South Korean, RM=sister, RS=enlist, SD=neutralize, SK=yahoo.com,SV=flash drive, TA=complete, TC=code,TN=paid, TO=agents, UE=bomb, UD=photos, VT=malware, VU=rsthnd7,WA=Mafia, WM=bribe, WO=computers, WT=disable, WV=microchip, XE=cripple, YE=corrupt, YS=send,ZM=stop`

const CipherPage = () => {

  const context = useContext(AppContext)
  const [data, setData] = useState({})

  useEffect(() => {
    setData(getCurrentHuntData())
    context.setLoading(false)
  }, [])

  const code_list = codes.split(`,`)
  let middle = Math.floor(code_list.length / 2)
  let first_half = code_list.slice(0, middle)
  let second_half = code_list.slice(middle)

  return(
    <LoggedInWrapper>
      {data.emergency_message !== undefined && (
        <>
          <div className="site-sub-header px-4 py-2">
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <Link to={`/`} className={`back-arrow`}>
                  <SvgArrow />
                </Link>
              </div>
              <div className="col-10 text-right">
                <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>
                  {data.emergency_message.display_title
                    ? data.emergency_message.display_title  + ` Cipher`
                    : `Emergency Message Cipher`}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            
            <div className={`card mx-4`}>
              <div className="card-header text-center">
                <h3>Cipher</h3>
              </div>
              <div className="card-body pb-3">
                <div className="row">
                  <div className="col-6">
                    <p>
                      {first_half.map((item, idx) => {
                        let [code, word] = item.split(`=`)
                        return <><span key={`col-1-${idx}`}>{code.trim()} = {word.trim()}</span><br/></>
                      })}
                    </p>
                  </div>
                  <div className="col-6">
                    <p>
                      {second_half.map((item, idx) => {
                        let [code, word] = item.split(`=`)
                        return <><span key={`col-2-${idx}`}>{code.trim()} = {word.trim()}</span><br/></>
                      })}
                    </p>
                  </div>
                </div>
                
              </div>

            </div>
            <div className="px-4 my-3">
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Link to={`/answers/`} className="btn btn-block btn-primary">{data.hunt.answer_sheet_title
                    ? data.hunt.answer_sheet_title
                    : `Team Answer Sheet`}</Link>
                </div>
                <div className="col-md-6 pb-3">
                  <Link to={`/emergency-message/`} className="btn btn-block btn-primary">{data.emergency_message.display_title ? data.emergency_message.display_title : `Emergency Message`}</Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </LoggedInWrapper>
  )
}

export default CipherPage